import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@headlessui/react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Button, ButtonWrapper } from "../components/Button";
import { Modal } from "../components/Dialog";
import Drawer from "../components/Drawer";
import { PhotoList } from "../components/PhotoList";
import { PhotoUpload } from "../components/PhotoUpload";
import { Spinner } from "../components/Spinner";
import { InspectionItemType } from "../types/Inspection";
import {
  trackIndicateIssue,
  trackUploadInspectionPhoto,
} from "../utils/analytics";
import { truncate } from "../utils/truncate";
import { InspectionChecklistOutletContent } from "./inspection-checklist";
import useLivlyUser from "@/context/UserProvider";

function InspectionChecklistSectionPage() {
  const isMobile = localStorage.getItem("mobile") === "true" || false;
  const { user } = useLivlyUser();
  const params = useParams<{ sectionId: string }>();
  const data = useOutletContext() as InspectionChecklistOutletContent;
  const section = data.inspection.inspectionSections.find(
    (s) => s.inspectionSectionTypeId === Number(params.sectionId)
  );
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const onClose = () => {
    setOpen(false);
    setTimeout(
      () =>
        navigate(`/lease/${user.propertyUnitLeaseId}/inspection-checklist`, {
          replace: true,
        }),
      500
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={section?.name ?? "Inspection Checklist"}
      hideCloseButton={isMobile}
    >
      <>
        <ul className="pb-24 divide-y divide-gray-200">
          {section?.inspectionItemTypes.map((item) => (
            <InspectionSectionListItem
              sectionId={params.sectionId!}
              key={item.inspectionItemTypeId}
              roomName={section.name}
              item={item}
              onUpdateItem={data.updateInspectionItem}
              onUploadPhotos={data.onUploadPhotos}
              isUploadingImages={data.isUploadingImages}
            />
          ))}
        </ul>
        <ButtonWrapper>
          <Button
            color="secondary"
            className="w-full md:w-auto"
            onClick={onClose}
          >
            Save
          </Button>
        </ButtonWrapper>
      </>
    </Drawer>
  );
}

export { InspectionChecklistSectionPage };

function InspectionSectionListItem({
  sectionId,
  roomName,
  item,
  onUpdateItem,
  onUploadPhotos,
  isUploadingImages,
}: {
  sectionId: string;
  roomName: string;
  item: InspectionItemType;
  onUpdateItem: (sectionId: string, item: InspectionItemType) => void;
  onUploadPhotos: (
    sectionId: string,
    item: InspectionItemType,
    decodedPhotos: File[]
  ) => Promise<void>;
  isUploadingImages: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li key={item.inspectionItemTypeId} className="py-4">
      <div className="flex items-center justify-between">
        <span>{item.name}</span>
        <label className="flex flex-col items-end">
          <Switch
            id={`${item.name}-issues`}
            checked={item.isFlagged ?? false}
            onChange={(isFlagged) => {
              trackIndicateIssue(roomName, item.name);
              onUpdateItem(sectionId, { ...item, isFlagged });
            }}
            className={classNames(
              item.isFlagged ? "bg-green-400" : "bg-gray-200",
              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            )}
          >
            <span className="sr-only">Has issues</span>
            <span
              className={classNames(
                item.isFlagged ? "translate-x-5" : "translate-x-0",
                "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            >
              <span
                className={classNames(
                  item.isFlagged
                    ? "opacity-0 ease-out duration-100"
                    : "opacity-100 ease-in duration-200",
                  "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                )}
                aria-hidden="true"
              >
                <svg
                  className="w-3 h-3 text-gray-400"
                  fill="none"
                  viewBox="0 0 12 12"
                >
                  <path
                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span
                className={classNames(
                  item.isFlagged
                    ? "opacity-100 ease-in duration-200"
                    : "opacity-0 ease-out duration-100",
                  "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                )}
                aria-hidden="true"
              >
                <svg
                  className="w-3 h-3 text-green-600"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
          <p className="mt-1 text-sm">
            {item.isFlagged ? "Issues" : "No Issues"}
          </p>
        </label>
      </div>
      {item.isFlagged && (
        <div className="mt-4">
          <button
            className="flex items-center w-full px-3 py-2 text-sm capitalize bg-transparent rounded-md hover:bg-gray-100"
            onClick={() => setIsOpen(true)}
          >
            {item.description ? (
              <span className="flex-1 text-left">
                {truncate(item.description, 200, "...")}
              </span>
            ) : (
              <div className="flex items-center flex-1 gap-2">
                <FontAwesomeIcon
                  icon={["fas", "pen-to-square"]}
                  className="text-red-400"
                />
                <span className="text-red-400 ">Describe your issue</span>
              </div>
            )}
            <FontAwesomeIcon icon="chevron-right" className="text-gray-400" />
          </button>
          <div>
            {(item.images ?? []).length > 0 && (
              <PhotoList
                images={item.images}
                onRemove={(uri) =>
                  onUpdateItem(sectionId, {
                    ...item,
                    images: item.images.filter((img) => img !== uri),
                  })
                }
              />
            )}
          </div>
          <PhotoUpload
            onFilesUploadStart={() => {}}
            onFilesUpload={(decodedFiles: File[]) => {
              trackUploadInspectionPhoto(roomName, item.name);
              onUploadPhotos(sectionId, item, decodedFiles);
            }}
            onFilesUploadError={(error) => {}}
          >
            {(props) => (
              <button
                className="flex items-center justify-center w-full gap-2 px-3 py-2 mt-4 text-sm capitalize bg-white border border-red-400 rounded-md hover:bg-gray-50"
                onClick={props.onClick}
                disabled={isUploadingImages}
              >
                {isUploadingImages ? (
                  <div className="flex items-center justify-center gap-2">
                    <Spinner />
                    <span>Uploading images...</span>
                  </div>
                ) : (
                  <>
                    <FontAwesomeIcon icon="image" className="text-red-400" />
                    <span className="text-red-400 ">Add Photos</span>
                  </>
                )}
              </button>
            )}
          </PhotoUpload>
        </div>
      )}
      <DescriptionModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        currentDescription={item.description ?? ""}
        onConfirm={(description) => {
          onUpdateItem(sectionId, { ...item, description });
          setIsOpen(false);
        }}
      />
    </li>
  );
}

function DescriptionModal({
  currentDescription,
  isOpen,
  onClose,
  onConfirm,
}: {
  currentDescription: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (description: string) => void;
}) {
  const [description, setDescription] = useState(currentDescription);

  return (
    <Modal open={isOpen} onClose={onClose} title="Description">
      <div>
        <div className="mt-4">
          <textarea
            rows={6}
            name="description"
            id="description"
            className="block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={description ?? ""}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="flex justify-end mt-4">
          <Button
            color="secondary"
            className="w-full md:w-auto"
            onClick={() => onConfirm(description)}
          >
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
}
